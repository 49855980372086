<template>
	<div class="generate-ai-org-message">
		<UiButton
			name=""
			:icon-name="'chat'"
			title="Сгенерировать сообщение"
			:btn-color="'transparent'"
			:disabled="isAiGenerateMessage"
			@click="generateAiMessage" />

		<AiOrgMessageToast />
	</div>
</template>

<script setup lang="ts">
	import { api } from "~/api/api";
	import type { IFetchError } from "ofetch";

	const emits = defineEmits<{
		(event: "message-generated", value: string): void;
	}>();

	const {
		activeEventStore,
		modalErrorStore: { open: showError },
		generateOrgMessageStore: { setAiGeneratesMessage, setAiGeneratedSuccess },
		generateOrgMessageStore,
	} = useStores();

	const { getActiveEvent } = storeToRefs(activeEventStore);
	const { isAiGenerateMessage } = storeToRefs(generateOrgMessageStore);

	const generateAiMessage = async () => {
		const {
			attributes: { group },
		} = getActiveEvent.value!;

		try {
			setAiGeneratesMessage(true);

			const data = await useOFetch<IApiResponsePayload<IWsResponse<IAiGenerateMessage>>>(api.orgMessageAi(group), {
				method: "POST",
			});

			setAiGeneratedSuccess(true);

			emits("message-generated", data.data.data.message);
		} catch (error) {
			const fetchError = error as IFetchError;

			setAiGeneratedSuccess(false);
			showError(fetchError.data?.message ?? fetchError.message ?? "Ошибка генерации сообщения", true);
		} finally {
			setAiGeneratesMessage(false);
		}
	};
</script>
